import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { eurexIndicatorsValueStorage } from '../example/eurexIndicatorsValueStorage'

export const eurex_power = createIndicator({
  displayName: 'Power',
  id: 'eurex-power',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //macd
      const macd1 = this._input(0)
      const macd2 = this._input(1)
      const macd3 = 9
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      //macd
      const fast = this.PineJS.Std.wma(close_array, macd1, this._context)
      const slow = this.PineJS.Std.wma(close_array, macd2, this._context)
      const dif = fast - slow
      const dif_array = this._context.new_var(dif)
      const dem = this.PineJS.Std.ema(dif_array, macd3, this._context)
      const osc = dif - dem

      const colorIndex = () => {
        if (osc >= 0) {
          eurexIndicatorsValueStorage.powerMacd = 1
          return 0
        } else {
          eurexIndicatorsValueStorage.powerMacd = -1
          return 1
        }
      }

      return [osc, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'powerPalette',
        type: 'colorer',
        target: 'plot_0',
        palette: 'powerPalette',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 20,
          visible: !0,
          color: '#f2c34f',
        },
      },
      precision: 4,
      inputs: {
        in_0: 12,
        in_1: 26,
      },
    },
    palettes: {
      powerPalette: {
        colors: {
          0: {
            color: '#da5441',
            width: 0,
            style: 0,
          },
          1: {
            color: '#00980a',
            width: 0,
            style: 0,
          },
          2: {
            color: '#5D616E',
            width: 0,
            style: 0,
          },
        },
      },
    },
    styles: {
      plot_0: {
        title: 'power',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: '長度',
        defval: 12,
        type: 'integer',
        min: 1,
        max: 120,
      },
      {
        id: 'in_1',
        name: '長度',
        defval: 26,
        type: 'integer',
        min: 1,
        max: 120,
      },
    ],
    is_price_study: !1,
    scriptIdPart: '',
  },
})
