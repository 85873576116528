import { css } from '@emotion/react'
import { Tooltip } from '@mui/material'
import Big from 'big.js'
import React, { memo } from 'react'
import { BLACK, globalGreen, globalGrey, globalRed, WHITE } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { toPercentage } from '~/utils/toPercentage'

/**
 * K棒
 *
 * 適用：單純顯示該symbol的本身狀況
 *
 * 不適用：比較其他symbols，極限是由該symbol本身的h,l決定，而非根據其他k棒的大小值標準化
 */
const SymbolCandleStick = memo<
  React.PropsWithChildren<{
    isDark?: boolean
    width?: number
    height?: number
    strokeWidth?: number
    upColor?: string
    dnColor?: string
    data: {
      open: number
      high: number
      low: number
      close: number
      prevRef: number
    }
  }>
>(function SymbolCandleStick(props) {
  const width = props.width || 32
  const height = props.height || 100
  const innerWidth = width - 4
  const innerHeight = height - 4
  const x = 1
  const { open, high, low, close, prevRef } = props.data
  const change = new Big(close).minus(prevRef).toNumber()

  let { openPercentage, highPercentage, lowPercentage, closePercentage, ratio } =
    processToPercentage(props.data)
  const isGrowing = open < close
  const redColor = props.upColor ? props.upColor : globalRed.r400
  const greenColor = props.upColor ? props.dnColor : globalGreen.g400
  const color = open - close === 0 ? globalGrey.g700 : isGrowing ? redColor : greenColor
  const lowestValue = Math.min(
    openPercentage,
    highPercentage,
    lowPercentage,
    closePercentage,
    ratio,
  )

  if (lowestValue < 0) {
    openPercentage -= lowestValue
    highPercentage -= lowestValue
    lowPercentage -= lowestValue
    closePercentage -= lowestValue
    ratio -= lowestValue
  }

  let [tempY1, tempY2] = [
    openPercentage / ratio,
    (openPercentage + (openPercentage - closePercentage)) / ratio,
  ]

  const [minHeight, maxHeight] = [Math.min(tempY1, tempY2), Math.max(tempY1, tempY2)]

  // 若太小，圖會在外面上面，多測試
  if (minHeight < 0) {
    tempY1 -= minHeight
    tempY2 -= minHeight
  }

  // 若太大，圖會在外面下面
  if (maxHeight > 1) {
    tempY1 -= minHeight
    tempY2 -= minHeight
  }

  // 若偏上面，往下位移
  while (Math.min(tempY1, tempY2) < 0.5 && Math.abs(tempY2 - tempY1) < 0.8) {
    tempY1 += 0.1
    tempY2 += 0.1
  }

  const HEIGHT_FACTOR = (height / 100) * 100
  const [openY, closeY] = [
    (tempY1 * HEIGHT_FACTOR).toFixed(2),
    (2 + tempY2 * HEIGHT_FACTOR).toFixed(2),
  ]

  const draw = (
    <svg
      css={css`
        &:hover .candle {
          opacity: 0.5;
        }
      `}
      stroke={props.isDark ? WHITE : BLACK}
      fill={color}
      strokeWidth={props.strokeWidth ? props.strokeWidth : 2}
      width={width}
      height={height}
      viewBox={` ${-width / 4} 10 ${width * 1.5} ${height}`}
    >
      {isNaN(tempY1) || isNaN(tempY2) || !isFinite(tempY1) || !isFinite(tempY2) ? (
        <g>
          <path
            d={`
          M ${x},${height / 2}
          L ${x + innerWidth},${height / 2}
        `}
          />
        </g>
      ) : (
        <g className='candle'>
          {/* 箱子 */}
          <path
            d={`
            M ${x},${openY}
            L ${x},${closeY}
            L ${x + innerWidth},${closeY}
            L ${x + innerWidth},${openY}
            L ${x},${openY}
          `}
          />
          {/* 下半部的線 */}
          {isGrowing ? (
            <path
              stroke={props.isDark ? WHITE : BLACK}
              d={`
              M ${x + innerWidth / 2}, ${openY}
              v ${((openPercentage - lowPercentage) / ratio) * HEIGHT_FACTOR}
            `}
            />
          ) : (
            <path
              d={`
              M ${x + innerWidth / 2}, ${closeY}
              v ${((closePercentage - lowPercentage) / ratio) * HEIGHT_FACTOR}
            `}
            />
          )}
          {/* 上半部的線 */}
          {isGrowing ? (
            <path
              stroke={props.isDark ? WHITE : BLACK}
              d={`
              M ${x + innerWidth / 2}, ${closeY}
              v ${((closePercentage - highPercentage) / ratio) * HEIGHT_FACTOR}
          `}
            />
          ) : (
            <path
              d={`
              M ${x + innerWidth / 2}, ${openY}
              v ${((openPercentage - highPercentage) / ratio) * HEIGHT_FACTOR}
            `}
            />
          )}
        </g>
      )}
    </svg>
  )
  return (
    <Tooltip
      title={
        <div
          css={css`
            ${flex.v.allCenter};
            p {
              font-size: 1rem;
              margin: 4px 0;
            }
          `}
        >
          <p>開盤 {formatTextBaseOnPriceRule(open)}</p>
          <p>最高 {formatTextBaseOnPriceRule(high)}</p>
          <p>最低 {formatTextBaseOnPriceRule(low)}</p>
          <p>現價 {formatTextBaseOnPriceRule(close)}</p>
          <p>平盤 {formatTextBaseOnPriceRule(prevRef)}</p>
          <p>
            漲跌 {change > 0 ? '+' : ''}
            {change}
          </p>
          <p>
            漲跌幅 {change > 0 ? '+' : ''}
            {((change / prevRef) * 100).toFixed(2)}%
          </p>
        </div>
      }
      placement='left-start'
    >
      {draw}
    </Tooltip>
  )
})

export default SymbolCandleStick

const formatTextBaseOnPriceRule = (value: number) => {
  const nDigit = value >= 100 ? 0 : value >= 50 ? 1 : 2
  return isNaN(value) ? '-' : Number(value).toFixed(nDigit)
}

const processToPercentage = (d: {
  open: number
  high: number
  low: number
  close: number
  prevRef: number
}) => {
  const data = d
  const chartData = {
    o: 0,
    h: 0,
    l: 0,
    c: 0,
    openPercentage: 0,
    highPercentage: 0,
    lowPercentage: 0,
    closePercentage: 0,
    ratio: 0,
  }

  if (!data) return chartData
  chartData.o = data.open
  chartData.h = data.high
  chartData.l = data.low
  chartData.c = data.close

  chartData.openPercentage = toPercentage(data.open, data.prevRef, true)
  chartData.highPercentage = toPercentage(data.high, data.prevRef, true)

  chartData.lowPercentage = toPercentage(data.low, data.prevRef, true)
  chartData.closePercentage = toPercentage(data.close, data.prevRef, true)

  chartData.ratio =
    Math.max(
      chartData.highPercentage,
      chartData.openPercentage,
      chartData.lowPercentage,
      chartData.closePercentage,
    ) -
    Math.min(
      chartData.highPercentage,
      chartData.openPercentage,
      chartData.lowPercentage,
      chartData.closePercentage,
    )
  return chartData
}
