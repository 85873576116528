import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore'
import { eurexIndicatorsValueStorage } from './eurexIndicatorsValueStorage'

export const eurexIndicatorValueProvider = createIndicator({
  displayName: '-',
  id: 'EurexIndicatorValueProvider',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length1 = 5
      const length2 = 20
      const length3 = 60
      const length4 = 120

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volumeValue = this.PineJS.Std.volume(this._context)
      const volume = this._context.new_var(volumeValue)
      close.get(200)
      high.get(200)
      low.get(200)
      volume.get(200)

      const ma5Value = this.average(close, length1)
      const ma20Value = this.average(close, length2)
      const ma60Value = this.average(close, length3)
      const ma120Value = this.average(close, length4)
      const ma5 = this._context.new_var(ma5Value)
      const ma20 = this._context.new_var(ma20Value)
      const ma60 = this._context.new_var(ma60Value)
      const ma120 = this._context.new_var(ma120Value)
      ma5.get(200)
      ma20.get(200)
      ma60.get(200)
      ma120.get(200)

      // Volume
      eurexIndicatorsValueStorage.highestVolume5 = this.highest(volume, length1)
      eurexIndicatorsValueStorage.highestVolume20 = this.highest(volume, length2)
      eurexIndicatorsValueStorage.highestVolume60 = this.highest(volume, length3)
      eurexIndicatorsValueStorage.lowestVolume5 = this.lowest(volume, length1)
      eurexIndicatorsValueStorage.lowestVolume20 = this.lowest(volume, length2)
      eurexIndicatorsValueStorage.lowestVolume60 = this.lowest(volume, length3)
      eurexIndicatorsValueStorage.averageVolume5 = this.average(volume, length1)
      eurexIndicatorsValueStorage.averageVolume20 = this.average(volume, length2)
      eurexIndicatorsValueStorage.averageVolume60 = this.average(volume, length3)

      // OHLC and RSV
      eurexIndicatorsValueStorage.dayOpen = this.ohlc.openArray.get(0)
      eurexIndicatorsValueStorage.dayHigh = this.ohlc.highArray.get(0)
      eurexIndicatorsValueStorage.dayLow = this.ohlc.lowArray.get(0)
      eurexIndicatorsValueStorage.dayClose = this.ohlc.closeArray.get(0)
      eurexIndicatorsValueStorage.dayVolume = volume.get(0)
      eurexIndicatorsValueStorage.highestHigh5 = this.highest(high, length1)
      eurexIndicatorsValueStorage.highestHigh20 = this.highest(high, length2)
      eurexIndicatorsValueStorage.highestHigh60 = this.highest(high, length3)
      eurexIndicatorsValueStorage.lowestLow5 = this.lowest(low, length1)
      eurexIndicatorsValueStorage.lowestLow20 = this.lowest(low, length2)
      eurexIndicatorsValueStorage.lowestLow60 = this.lowest(low, length3)

      eurexIndicatorsValueStorage.rsv5 =
        ((close.get(0) - this.lowest(low, length1)) /
          (this.highest(high, length1) - this.lowest(low, length1))) *
        100
      eurexIndicatorsValueStorage.rsv20 =
        ((close.get(0) - this.lowest(low, length2)) /
          (this.highest(high, length2) - this.lowest(low, length2))) *
        100
      eurexIndicatorsValueStorage.rsv60 =
        ((close.get(0) - this.lowest(low, length3)) /
          (this.highest(high, length3) - this.lowest(low, length3))) *
        100

      // Mov. Avg.
      eurexIndicatorsValueStorage.ma5 = ma5Value
      eurexIndicatorsValueStorage.ma20 = ma20Value
      eurexIndicatorsValueStorage.ma60 = ma60Value
      eurexIndicatorsValueStorage.ma120 = ma120Value

      // Bias
      eurexIndicatorsValueStorage.bias5 = this.bias(length1)
      eurexIndicatorsValueStorage.bias20 = this.bias(length2)
      eurexIndicatorsValueStorage.bias60 = this.bias(length3)
      eurexIndicatorsValueStorage.bias120 = this.bias(length4)

      // Slopes = (ma[0] - ma[1]) / ma[1] ??
      eurexIndicatorsValueStorage.slope5 = ma5.get(0) - ma5.get(1)
      eurexIndicatorsValueStorage.slope20 = ma20.get(0) - ma20.get(1)
      eurexIndicatorsValueStorage.slope60 = ma60.get(0) - ma60.get(1)
      eurexIndicatorsValueStorage.slope120 = ma120.get(0) - ma120.get(1)

      // Offset value
      eurexIndicatorsValueStorage.offsetValue5 = ma5.get(length1 - 1)
      eurexIndicatorsValueStorage.offsetValue20 = ma20.get(length2 - 1)
      eurexIndicatorsValueStorage.offsetValue60 = ma60.get(length3 - 1)
      eurexIndicatorsValueStorage.offsetValue120 = ma120.get(length4 - 1)

      // Amp
      eurexIndicatorsValueStorage.currentAmp = high - low
      eurexIndicatorsValueStorage.averageAmp05 =
        this.PineJS.Std.sma(high, length1, this._context) -
        this.PineJS.Std.sma(low, length1, this._context)
      eurexIndicatorsValueStorage.averageAmp20 =
        this.PineJS.Std.sma(high, length2, this._context) -
        this.PineJS.Std.sma(low, length2, this._context)
      eurexIndicatorsValueStorage.averageAmp60 =
        this.PineJS.Std.sma(high, length3, this._context) -
        this.PineJS.Std.sma(low, length3, this._context)
      eurexIndicatorsValueStorage.averageAmp120 =
        this.PineJS.Std.sma(high, length4, this._context) -
        this.PineJS.Std.sma(low, length4, this._context)

      const interval = context.symbol.period
      if (interval === '1W') {
        eurexIndicatorsValueStorage.internalType = '週'
      }
      if (interval === '1D') {
        eurexIndicatorsValueStorage.internalType = '日'
      }
      if (interval <= '15') {
        eurexIndicatorsValueStorage.internalType = '分鐘'
      }
      if (interval === '60' || interval === '30') {
        eurexIndicatorsValueStorage.internalType = '小時'
      }

      //--盤勢狀態使用的state
      const ma = this.PineJS.Std.sma(close, 60, this._context)
      const dc = this.ladderChannl(length)
      const mid = dc[4]

      if (close > mid && dc[4] >= dc[5] && close > ma) {
        eurexIndicatorsValueStorage.powerTrend = 1
      }
      if (close < mid && dc[4] <= dc[5] && close < ma) {
        eurexIndicatorsValueStorage.powerTrend = -1
      }

      return [NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !1,
          color: '#fe80c0',
        },
      },
    },
    is_price_study: !0,
    scriptIdPart: '',
    inputs: [],
  },
})
